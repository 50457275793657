import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
    selector: 'lib-button-multi-line',
    standalone: true,
    imports: [
        NgClass
    ],
    templateUrl: './lib-button-multi-line.component.html',
    styleUrl: './lib-button-multi-line.component.scss'
})
export class LibButtonMultiLineComponent {
    @Input() imgUrl: string = "assets/images/three-blue-star.svg";
    @Input() primaryText: string;
    @Input() secondaryText: string;
    @Input() buttonType: BUTTON_MULTI_LINE_TYPE = BUTTON_MULTI_LINE_TYPE.WHITE;
    @Input() backgroundColor: string = 'var(--color-white)';
    @Input() chipText: string;

    @Output() onCardClick = new EventEmitter();

    protected readonly BUTTON_MULTI_LINE_TYPE = BUTTON_MULTI_LINE_TYPE;

    onClickButton(): void {
        this.onCardClick.emit();
    }

}

export enum BUTTON_MULTI_LINE_TYPE {
    WHITE = "white",
    COLOR = "color",
}
