<div class="bottom-toolbar-container box-shadow">
    @for (libBottomToolbarBottomConfig of libBottomToolbarConfig?.libBottomToolbarBottomConfigs; track libBottomToolbarBottomConfig?.id) {
        @switch (libBottomToolbarBottomConfig.type) {
            @case (LibBottomToolbarBottomConfigType.BUTTON) {
                <lib-button [libType]="libBottomToolbarBottomConfig?.buttonType"
                            [libSubType]="libBottomToolbarBottomConfig?.buttonSubType"
                            [iconClass]="libBottomToolbarBottomConfig?.iconClass"
                            [iconPosition]="libBottomToolbarBottomConfig?.iconPosition"
                            [tooltipText]="libBottomToolbarBottomConfig?.tooltip"
                            (buttonClick)="onSubmitLibButton(libBottomToolbarBottomConfig?.id)">
                    @if (libBottomToolbarBottomConfig?.buttonText) {
                        {{ libBottomToolbarBottomConfig?.buttonText }}
                    }
                </lib-button>
            }
            @case (LibBottomToolbarBottomConfigType.CUSTOM) {
                <ng-container [ngTemplateOutlet]="libBottomToolbarBottomConfig.customTemplateRef"></ng-container>
            }
        }
    }
</div>
