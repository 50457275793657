<button class="w-full transform transition-transform duration-300 hover:scale-105 hover:shadow-lg multi-line-button" (click)="onClickButton()">
    <div class="flex flex-row gap-3 border-stroke button-container items-center"
         [style.background]="backgroundColor">
        @if (imgUrl) {
            <div class="w-10 h-10 min-w-10 rounded-full">
                <img class="w-full min-w-10" [src]="imgUrl" alt="card-image">
            </div>
        }
        <div class="flex flex-row justify-between w-full items-center">
            <div class="flex flex-col items-start justify-start">
                @if (primaryText) {
                    <h3 class="font-semibold text-sm sm:text-base text-start break-words"
                        [ngClass]="buttonType == BUTTON_MULTI_LINE_TYPE.WHITE ? 'text-heading-primary' : 'text-white'">
                        {{ primaryText }}
                    </h3>
                }
                @if (secondaryText) {
                    <h3 class="font-normal text-sm sm:text-base text-start w-44 sm:w-full"
                        [ngClass]="buttonType == BUTTON_MULTI_LINE_TYPE.WHITE ? 'text-heading-primary' : 'text-white'">
                        {{ secondaryText }}
                    </h3>
                }
            </div>
            @if (chipText) {
                <h3 class="font-normal text-sm text-primary px-3 border-stroke rounded-xl bg-white">{{ chipText }}</h3>
            }
        </div>
    </div>
</button>
