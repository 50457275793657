<div [ngClass]="{'card-skeleton' : true,
    'card-skeleton-hr' : layout === SKELETON_LAYOUT_TYPE.HORIZONTAL,
    'card-skeleton-vertical' : layout === SKELETON_LAYOUT_TYPE.VERTICAL}">
    <div class="animated-background">
        <div class="card-skeleton-img"></div>
        <div class="skel-mask-container">
            <div class="skel-mask skel-mask-1"></div>
            <div class="skel-mask skel-mask-3"></div>
            <div class="skel-mask skel-mask-5"></div>
            <div class="skel-mask skel-mask-6"></div>
            <div class="skel-mask skel-mask-7"></div>
            <div class="skel-mask skel-mask-8"></div>
        </div>
    </div>
</div>
