import {Component, Input, OnInit} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
    selector: 'lib-card-skeleton',
    standalone: true,
    imports: [
        NgClass
    ],
    templateUrl: './lib-card-skeleton.component.html',
    styleUrls: ['./lib-card-skeleton.component.scss']
})
export class LibCardSkeletonComponent implements OnInit {

    @Input()
    layout: SKELETON_LAYOUT_TYPE = SKELETON_LAYOUT_TYPE.DEFAULT;

    protected readonly SKELETON_LAYOUT_TYPE = SKELETON_LAYOUT_TYPE;

    constructor() {
    }

    ngOnInit(): void {
    }
}

export enum SKELETON_LAYOUT_TYPE {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
    DEFAULT = 'default'
}
