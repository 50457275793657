<mat-stepper labelPosition="bottom"
             [linear]="false"
             [disableRipple]="true"
             [selectedIndex]="activeStep?.index"
             (selectionChange)="changeStep($event.selectedIndex)">
    @for (step of steps; track step?.id) {
        <mat-step>
            <ng-template matStepLabel>
                <div class="font-medium">{{ step?.title }}</div>
            </ng-template>
        </mat-step>
    }
</mat-stepper>
