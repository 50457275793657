import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserMeOutDto} from "../model/user-me.model";
import {UserMeService} from "../service/user-me.service";
import {ROUTE} from "../constant/routing.constant";
import {DomainUserOutDto, USER_TYPE} from "../model/user.model";
import {UserTokenFirebaseService} from "../service/firebase/user-token-firebase.service";

@Injectable({
    providedIn: 'root'
})

export class AuthStore {

    private _loggedInUser = new BehaviorSubject<UserMeOutDto | null>(null);
    public loggedInUser: Observable<UserMeOutDto | null>;
    private userMeOutDto: UserMeOutDto;

    constructor(private userMeService: UserMeService,
                private router: Router,
                private userTokenFirebaseService: UserTokenFirebaseService) {
        this.loggedInUser = this._loggedInUser.asObservable();
    }

    getLoggedInUser(): Observable<UserMeOutDto> {
        return this.userMeOutDto
            ? of(this.userMeOutDto)
            : this.getUserFromServer();
    }

    reload(): Promise<UserMeOutDto> {
        return new Promise<UserMeOutDto>((resolve, reject) => {
            this.getUserFromServer().subscribe({
                next: (userMeOutDto) => {
                    this.userMeOutDto = userMeOutDto;
                    resolve(userMeOutDto);
                },
                error: () => {
                    reject(null);
                }
            });
        });
    }

    getToken(): string {
        return localStorage.getItem(ROUTE.TOKEN);
    }

    getUserId(): string {
        return this._loggedInUser.value?.userOutDto?.id;
    }

    getCandidateId(): string {
        return this._loggedInUser.value?.domainUserOutDto?.candidateId;
    }

    getDomainUserOutDto(): DomainUserOutDto {
        return this._loggedInUser.value?.domainUserOutDto;
    }

    getUserType(): USER_TYPE {
        return this.userMeOutDto?.userOutDto?.userType || null;
    }

    clearLocalStorage(): void {
        localStorage.removeItem(ROUTE.TOKEN);
        localStorage.removeItem(ROUTE.COMPANY_ID);
        localStorage.removeItem(ROUTE.BACK_URL);
        localStorage.removeItem(ROUTE.NAVIGATE_BACK_URL);
        localStorage.removeItem(ROUTE.PORTFOLIO_STATE);
        localStorage.clear();
    }

    isLoggedIn(): boolean {
        return this.getToken() !== null;
    }

    logout(): void {
        this.userTokenFirebaseService.delete().then(() => {
            this.clearUserResources();
        }).catch(error => {
            console.log('error:', error);
            this.clearUserResources();
        });
    }

    private clearUserResources() {
        this.clearLocalStorage();
        this.redirectToLogin();
    }

    private getUserFromServer() {
        return this.userMeService.find()
            .pipe(map(userMeOutDto => {
                this.processUserMeData(userMeOutDto);
                return userMeOutDto;
            }));
    }

    private processUserMeData(userMeOutDto: UserMeOutDto) {
        this.userTokenFirebaseService.create(userMeOutDto?.userOutDto?.id);
        this.userMeOutDto = userMeOutDto;
        this._loggedInUser.next(userMeOutDto);
    }

    private redirectToLogin() {
        this.router.navigate([ROUTE.AUTH, ROUTE.LOGIN]).then(() => {
            window.location.reload();
        });
    }
}
