import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClientWrapperService} from "../../../../home-layout/common/service/http-client-wrapper.service";
import {JobPostOutDto} from "../../../../home-layout/job/common/service/job-post.service";

@Injectable({
    providedIn: 'root'
})
export class PublicJobPostService {
    private publicJobPostApiUrl = '/public/jobPosts';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    find(jobPostId: string): Observable<JobPostOutDto> {
        return this.httpClientWrapperService.get<JobPostOutDto>(`${this.publicJobPostApiUrl}/${jobPostId}`);
    }
}
