import {Component, Input, OnInit} from '@angular/core';
import {NgClass} from "@angular/common";


@Component({
    selector: 'lib-header',
    standalone: true,
    imports: [
        NgClass
    ],
    templateUrl: './lib-header.component.html',
    styleUrls: ['./lib-header.component.scss']
})
export class LibHeaderComponent implements OnInit {

    @Input()
    headerTitle: string;
    @Input()
    headerSubTitle: string;
    @Input()
    libHeaderType = HEADER_TYPE.H1;
    @Input()
    libHeaderFontWeight = HEADER_FONT_WEIGHT.BOLD;


    constructor() {
    }

    ngOnInit(): void {
    }
}

export enum HEADER_TYPE {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6'
}

export enum HEADER_FONT_WEIGHT {
    REGULAR = 'regular',
    SEMI_BOLD = 'semi-bold',
    BOLD = 'bold'
}
