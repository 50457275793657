<div class="w-full">
    @if (headerTitle) {
        <div class="header-title"
             [ngClass]="[libHeaderType, libHeaderFontWeight]">
            {{ headerTitle }}
        </div>
    }
    @if (headerSubTitle) {
        <div class="header-subtitle mt-4">
            {{ headerSubTitle }}
        </div>
    }
</div>
