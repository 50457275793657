import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWrapperService} from "../../../common/service/http-client-wrapper.service";
import {
    ApplicantHasCommentOutDto,
    ApplicantHasHiringRoundOutDto,
} from "./candidate-has-job-application.service";
import {
    ApplicantStatusOutDto,
    JobPostHasHiringRoundMinOutDto, JobPostMinOutDto,
    PlacementDriveMinOutDto
} from "./candidate-has-job.service";
import {SearchInDto, SearchOutDto} from "../../../common/model/search.model";
import {ApplicantHasFieldValueOutDto} from "./applicant-has-field-value.service";
import {ApplicantHasDocumentOutDto} from "./applicant-has-document.service";
import {ApplicantHasResumeOutDto} from "./applicant-has-resume.service";
import {JobPostOutDto} from "./job-post.service";

@Injectable({
    providedIn: 'root'
})
export class ApplicantService {

    private applicantsUrl = '/applicants';
    private searchUrl = '/search';
    private applyUrl = '/apply';
    private bulkUrl = '/bulk';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    search(applicantSearchInDto?: ApplicantSearchInDto): Observable<SearchOutDto<ApplicantMinOutDto>> {
        return this.httpClientWrapperService.post(`${this.getUrl()}${this.searchUrl}`, applicantSearchInDto);
    }

    find(applicantId: string): Observable<ApplicantOutDto> {
        return this.httpClientWrapperService.get<ApplicantOutDto>(`${this.getUrl()}/${applicantId}`);
    }

    create(applicationInDto: ApplicantInDto): Observable<ApplicantOutDto> {
        return this.httpClientWrapperService.post<ApplicantOutDto>(`${this.getUrl()}`, applicationInDto);
    }

    update(applicantId: string, applicationInDto: ApplicantInDto): Observable<ApplicantOutDto> {
        return this.httpClientWrapperService.put<ApplicantOutDto>(`${this.getUrl()}/${applicantId}`, applicationInDto);
    }

    updateStatus(applicantId: string, applicantStatusUpdateInDto: ApplicantStatusUpdateInDto): Observable<ApplicantOutDto> {
        return this.httpClientWrapperService.patch<ApplicantOutDto>(`${this.getUrl()}/${applicantId}`, applicantStatusUpdateInDto);
    }

    markJobAsApplied(applicantId: string): Observable<ApplicantOutDto> {
        return this.httpClientWrapperService.patch<ApplicantOutDto>(`${this.getUrl()}/${applicantId}${this.applyUrl}`);
    }

    bulkUpdate(applicantBulkUpdateInDto: ApplicantBulkUpdateInDto) {
        return this.httpClientWrapperService.patch(`${this.getUrl()}${this.bulkUrl}`, applicantBulkUpdateInDto);
    }

    delete(applicantId: string) {
        return this.httpClientWrapperService.delete(`${this.getUrl()}/${applicantId}`);
    }

    private getUrl(): string {
        return this.applicantsUrl;
    }
}

export class ApplicantDto {
    name: string;
    email: string;
    jobPostTitle: string;
    phoneNumber: string;
    address: string;
    personalWebsiteLink: string;
}

export class ApplicantInDto extends ApplicantDto {
    jobPostId: string;
    candidateId: string;
    placementDriveId?: string;
    currentApplicantStatusId?: string;
}

export class ApplicantStatusUpdateInDto {
    currentApplicantStatusId?: string;
}

export class ApplicantSearchInDto extends SearchInDto {
    employerId?: string;
    jobPostId?: string;
    placementDriveId?: string;
    employerIds?: string[];
    jobPostIds?: string[];
    placementDriveIds?: string[];
    currentHiringRoundIds?: string[];
    resumeScore?: number;
    currentConfigHiringRoundIds?: string[];
    applicantStatuses?: string[];
    applicantStatusIds?: string[];
}

export class ApplicantBulkUpdateInDto {
    applicantIds: string [];
    currentApplicantStatusId?: string;
    comment: string;
    commentBy: string;
    // TODO: will add in next branch COMMENT_TYPE.COMMENT
    commentType: any = '';
    currentHiringRoundId: string;
    notifyToApplicant: boolean = false;
}

export class ApplicantMinOutDto extends ApplicantDto {
    id: string;
    jobPostMinOutDto: JobPostMinOutDto;
    currentHiringRoundOutDto: JobPostHasHiringRoundMinOutDto;
    currentApplicantStatusOutDto: ApplicantStatusOutDto;
    placementDriveMinOutDto: PlacementDriveMinOutDto;
    createdAt: string;
    lastModifiedAt: string;
}

export class ApplicantOutDto extends ApplicantDto {
    id: string;
    jobPostOutDto: JobPostOutDto;
    candidateResumeId: string;
    applicantHasFieldValueOutDtos: ApplicantHasFieldValueOutDto[];
    applicantHasCommentOutDtos: ApplicantHasCommentOutDto[];
    applicantHasDocumentOutDtos: ApplicantHasDocumentOutDto[];
    applicantHasHiringRoundOutDtos: ApplicantHasHiringRoundOutDto[];
    applicantHasResumeOutDtos: ApplicantHasResumeOutDto[];
    currentHiringRoundOutDto: JobPostHasHiringRoundMinOutDto;
    placementDriveMinOutDto: PlacementDriveMinOutDto;
    currentApplicantStatusOutDto: ApplicantStatusOutDto;
    resumeScore: number;
    appliedAt: string;
    createdAt: string;
    lastModifiedAt: string;
}
