import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {
    BUTTON_ICON_POSITION,
    BUTTON_SUB_TYPE,
    BUTTON_TYPE,
    LibButtonComponent
} from "../button/lib-button/lib-button.component";
import {NgTemplateOutlet} from "@angular/common";

@Component({
    selector: 'lib-bottom-toolbar',
    standalone: true,
    imports: [
        LibButtonComponent,
        NgTemplateOutlet
    ],
    templateUrl: './lib-bottom-toolbar.component.html',
    styleUrl: './lib-bottom-toolbar.component.scss'
})
export class LibBottomToolbarComponent implements OnInit {
    @Input() libBottomToolbarConfig: LibBottomToolbarConfig;

    @Output() onClicked: EventEmitter<string> = new EventEmitter<string>();

    protected readonly LibBottomToolbarBottomConfigType = LibBottomToolbarBottomConfigType;

    ngOnInit(): void {
    }

    onSubmitLibButton(buttonId: string): void {
        this.onClicked.emit(buttonId);
    }
}

export interface LibBottomToolbarConfig {
    libBottomToolbarBottomConfigs: LibBottomToolbarBottomConfig[];
}

export interface LibBottomToolbarBottomConfig {
    id: string,
    type: LibBottomToolbarBottomConfigType,
    buttonText?: string;
    tooltip?: string;
    buttonType?: BUTTON_TYPE;
    buttonSubType?: BUTTON_SUB_TYPE;
    iconClass?: string;
    iconImage?: string;
    iconPosition?: BUTTON_ICON_POSITION;
    customTemplateRef?: TemplateRef<any>;
}

export enum LibBottomToolbarBottomConfigType {
    BUTTON = 'BUTTON',
    CUSTOM = 'CUSTOM',
}
