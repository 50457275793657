import {ContactPersonOutDto} from "./contact-person.model";
import {AttachmentOutDto} from "../component/upload/common/service/document.service";

export class UserCommonDto {
    username: string;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    contactNumberType?: string; // TODO : USE CONTACT_NUMBER_TYPE HERE
    userRole: string;
    enabled: boolean;
    userType: USER_TYPE;
}

export class UserInDto extends UserCommonDto {
    password: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    userProfileInDto: UserProfileInDto;
}

export class UserOutDto extends UserCommonDto {
    public id: string;
    userProfileOutDto: UserProfileOutDto;
}

export class UserMinOutDto extends UserCommonDto {
    public id: string;
    profileImageUrl: string;
}

export class UserUpdateInDto {
    username: string;
    firstName: string;
    lastName: string;
    contactNumber: string;
    email: string;
    userRole: string;
    enabled: boolean;
    userType: USER_TYPE;
}

export class UserProfileInDto {
    profileImageAttachmentId?: string;
    calendlyLink?: string;
}

export class UserProfileOutDto {
    profileImageOutDto: AttachmentOutDto;
    calendlyLink?: string;
}

export class SignUpOutDto {
    userOutDto: UserOutDto;
    jwtTokenDto: JwtTokenDto;
}

export class LoginInDto {
    email: string;
    password: string;
    domain: string;
}

export class SocialLoginInDto {
    idToken: string;
    emailAddress?: string;
    signInProvider?: SIGN_IN_PROVIDER;
}

export class LinkedInCredInDto {
    code?: string;
    clientId?: string;
    redirectUri?: string;
    clientSecret?: string;
    grantType?: string;
    provider?: SIGN_IN_PROVIDER;
}

export class LinkedInUserOutDto {
    linkedInUserInfoOutDto: LinkedInUserInfoOutDto;
    loginOutDto: LoginOutDto;
}

export class LinkedInUserInfoOutDto {
    email?: string;
    name?: string;
    picture?: string;
}

export class JwtTokenDto {
    accessToken: string;
    refreshToken: string;
}

export class LoginOutDto {
    jwtTokenDto: JwtTokenDto;
    userOutDto: UserOutDto;
    domainUserOutDto: DomainUserOutDto;
}

export class ForgotPasswordInDto {
    email: string;
}

export class UsernameInDto {
    email: string;
}

export class ForgotPasswordOutDto {
    email: string;
    username: string;
    otp: string;
}

export class DomainUserOutDto {
    candidateId: string;
    profileImageUrl?: string;
    planType: CANDIDATE_PLAN_TYPE;
    subscriptionUntil: Date;
    totalResumes: number;
    totalWebsites: number;
    totalCoverLetters: number;
    contactPersonOutDto?: ContactPersonOutDto;
    defaultBatchOutDto?: DefaultBatchOutDto;
}

export class DefaultBatchOutDto {
    id: string;
    name: string;
    domain: string;
    universityId: string;
}

export class ResetPasswordInDto {
    password: string;
}

export class EmailOutDto {
    to: string;
    status: number;
    responseBody: string;
}

export class VerifyOtpOutDto {
    email: string;
}

export enum USER_TYPE {
    CANDIDATE = 'CANDIDATE',
    EMPLOYER = 'EMPLOYER',
    UNIVERSITY = 'UNIVERSITY'
}

export enum CANDIDATE_PLAN_TYPE {
    FREEMIUM = 'FREEMIUM',
    CANDIDATE_STANDOUT = 'CANDIDATE_STANDOUT',
    CANDIDATE_PROFESSIONAL = 'CANDIDATE_PROFESSIONAL'
}

export enum CANDIDATE_PLAN_TYPE_DISPLAY {
    FREEMIUM = 'Freemium Plan',
    CANDIDATE_STANDOUT = 'Plus Plan',
    CANDIDATE_PROFESSIONAL = 'Pro Plan'
}

export enum SIGN_IN_PROVIDER {
    USERNAME_PASSWORD = 'USERNAME_PASSWORD',
    GOOGLE = 'GOOGLE',
    LINKEDIN = 'LINKEDIN'
}
