import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {LibStepperModel} from "../common/model/lib-stepper.model";
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";

@Component({
    selector: 'lib-stepper',
    standalone: true,
    imports: [
        MatStepper,
        MatStep,
        MatStepLabel
    ],
    templateUrl: './lib-stepper.component.html',
    styleUrl: './lib-stepper.component.scss'
})
export class LibStepperComponent implements OnInit, OnDestroy, OnChanges {

    @Input() steps: LibStepperModel[] = [];
    @Input() activeStepIndex: number = 0;
    @Input() goToStepId: string;
    @Output() selectionChanged = new EventEmitter();
    @Output() onStepChange = new EventEmitter();

    activeStep: LibStepperModel;
    private paramSubscription: Subscription;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.subscribeToQueryParams();
    }

    ngOnDestroy(): void {
        this.paramSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this.goToStep(this.goToStepId);
    }

    goToStep(stepId: string): void {
        const step = this.steps.find(step => step.id === stepId) || this.steps[0];
        this.setActiveStep(step);
    }

    changeStep(stepIndex: number): void {
        const activeStep = this.steps[stepIndex];
        this.setActiveStep(activeStep);
        this.selectionChanged.emit(activeStep.index);
    }

    private setActiveStep(libStepperModel: LibStepperModel): void {
        this.activeStep = libStepperModel;
        this.onStepChange.emit(this.activeStep);
        this.router.navigate([], this.setQueryParams(libStepperModel?.id));
    }

    private setQueryParams(activeStepId: string): NavigationExtras {
        return {
            relativeTo: this.activatedRoute,
            queryParams: {step: activeStepId},
            queryParamsHandling: 'merge'
        }
    }

    private subscribeToQueryParams(): void {
        this.paramSubscription = this.activatedRoute.queryParamMap.subscribe(queryParams => {
            const activeStepId = queryParams.get('step');
            const activeStep = this.steps.find(step => step?.id === activeStepId) || this.steps[0];
            this.changeStep(activeStep.index);
        });
    }
}
