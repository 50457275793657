import {LibStepperModel} from "../../../../../tal-library/datasheet/common/model/lib-stepper.model";

export enum JOB_APPLY_STEP_NAME {
    ACCOUNT_INFORMATION = 'account-information',
    YOUR_RESUME = 'your-resume',
    QUESTIONNAIRE = 'questionnaire'
}

export enum JOB_APPLY_STEP_NAME_DISPLAY {
    ACCOUNT_INFORMATION = 'Account Info',
    YOUR_RESUME = 'Your Resume',
    QUESTIONNAIRE = 'Questionnaire'
}

export class JobApplyUtil {
    public static getStepsForLoggedInUser(): LibStepperModel[] {
        return [
            {
                index: 0,
                previousId: null,
                id: JOB_APPLY_STEP_NAME.YOUR_RESUME,
                title: JOB_APPLY_STEP_NAME_DISPLAY.YOUR_RESUME,
                nextId: JOB_APPLY_STEP_NAME.QUESTIONNAIRE
            },
            {
                index: 1,
                previousId: JOB_APPLY_STEP_NAME.YOUR_RESUME,
                id: JOB_APPLY_STEP_NAME.QUESTIONNAIRE,
                title: JOB_APPLY_STEP_NAME_DISPLAY.QUESTIONNAIRE,
                nextId: null
            }
        ] as LibStepperModel[];
    }
    public static getStepsForNonLoggedInUser(): LibStepperModel[] {
        return [
            {
                index: 0,
                previousId: null,
                id: JOB_APPLY_STEP_NAME.ACCOUNT_INFORMATION,
                title: JOB_APPLY_STEP_NAME_DISPLAY.ACCOUNT_INFORMATION,
                nextId: JOB_APPLY_STEP_NAME.YOUR_RESUME
            },
            {
                index: 1,
                previousId: JOB_APPLY_STEP_NAME.ACCOUNT_INFORMATION,
                id: JOB_APPLY_STEP_NAME.YOUR_RESUME,
                title: JOB_APPLY_STEP_NAME_DISPLAY.YOUR_RESUME,
                nextId: JOB_APPLY_STEP_NAME.QUESTIONNAIRE
            },
            {
                index: 2,
                previousId: JOB_APPLY_STEP_NAME.YOUR_RESUME,
                id: JOB_APPLY_STEP_NAME.QUESTIONNAIRE,
                title: JOB_APPLY_STEP_NAME_DISPLAY.QUESTIONNAIRE,
                nextId: null
            }
        ] as LibStepperModel[];
    }

}
