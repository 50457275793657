<div class="bg-white account-info-card border-stroke w-full sm:mb-0 mb-16 p-5">
    <div class="card text-center">
        <lib-header headerTitle="Account Info"
                    headerSubTitle="Please select the resume that fit for this job!">
        </lib-header>
        <div class="w-full my-6">
            <lib-button-multi-line primaryText="Upload Your Resume & Sign up"
                                   secondaryText="Craft your standout resume with AI"
                                   backgroundColor="var(--card-background-gradient)"
                                   [buttonType]="BUTTON_MULTI_LINE_TYPE.COLOR"
                                   (onCardClick)="onCardClick()">
            </lib-button-multi-line>
        </div>
        <div class="text-heading-secondary text-base text-center mt-6 tracking-normal m-auto">
            Already have an account?
        </div>
        <div class="text-primary font-semibold mt-2 text-center cursor-pointer"
             (click)="navigateToLogin()">
            Sign In
        </div>
    </div>
</div>

