import {NOTIFICATION_EVENT_TYPE, NotificationOutDto} from "../service/notification.service";
import {
    LibBottomToolbarBottomConfig,
    LibBottomToolbarBottomConfigType
} from "../../../../../tal-library/lib-bottom-toolbar/lib-bottom-toolbar.component";
import {CONSTANT} from "../../../common/constant/constant";
import {
    BUTTON_ICON_POSITION,
    BUTTON_SUB_TYPE,
    BUTTON_TYPE
} from "../../../../../tal-library/button/lib-button/lib-button.component";

export class NotificationUtil {
    private static notificationUtil: NotificationUtil;

    // NOTE: Method to get the single instance of the class
    public static getInstance(): NotificationUtil {
        if (!NotificationUtil.notificationUtil) {
            NotificationUtil.notificationUtil = new NotificationUtil();
        }
        return NotificationUtil.notificationUtil;
    }

    getDescription(notificationOutDto: NotificationOutDto): string {
        switch (notificationOutDto?.notificationEventType) {
            case NOTIFICATION_EVENT_TYPE.APPLICANT_STATUS_CHANGED:
                return `Update : Your ${notificationOutDto?.jobPostName} application is now '${notificationOutDto?.currentApplicantStatus || notificationOutDto?.applicantStatus}' ${notificationOutDto?.applicantName} !`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_HIRING_ROUND_CHANGED:
                return `Your application for ${notificationOutDto?.jobPostName} is under process for '${notificationOutDto?.currentHiringRoundName}' round.
                We'll keep you posted on any updates!`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_COMMENT:
                return this.getComment(notificationOutDto);
            case NOTIFICATION_EVENT_TYPE.BULK_MODIFIED:
                return this.getEmailNotification(notificationOutDto);
            case NOTIFICATION_EVENT_TYPE.PLACEMENT_DRIVE_INVITATION:
            case NOTIFICATION_EVENT_TYPE.UNIVERSITY_BROADCAST:
                return `Message : ${notificationOutDto?.comment}`;
            default:
                return 'Message';
        }
    }

    private getEmailNotification(notificationOutDto: NotificationOutDto) {
        if (notificationOutDto?.subject && notificationOutDto?.message) {
            return `${notificationOutDto?.companyName} has a message to your ${notificationOutDto?.jobPostName} application:` + `<br/>${notificationOutDto?.subject} \n\n${notificationOutDto?.message}`;
        } else {
            return `--`;
        }
    }

    private getComment(notificationOutDto: NotificationOutDto) {
        if (notificationOutDto?.comment) {
            return `Message : ${notificationOutDto?.companyName} has a message to your ${notificationOutDto?.jobPostName} application: ${notificationOutDto?.comment}`;
        }
        if (notificationOutDto?.subject && notificationOutDto?.message) {
            return `${notificationOutDto?.companyName} has a message to your ${notificationOutDto?.jobPostName} application:` + `<br/>${notificationOutDto?.subject} \n\n${notificationOutDto?.message}`;
        } else {
            return `--`;
        }
    }

    getTitle(notificationOutDto: NotificationOutDto): string {
        switch (notificationOutDto?.notificationEventType) {
            case NOTIFICATION_EVENT_TYPE.APPLICANT_STATUS_CHANGED:
                return `📣 Job Application Status`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_HIRING_ROUND_CHANGED:
                return `🚀 Job Application Updates`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_COMMENT:
            case NOTIFICATION_EVENT_TYPE.BULK_MODIFIED:
                return `📧 You have a new message`;
            case NOTIFICATION_EVENT_TYPE.PLACEMENT_DRIVE_INVITATION:
            case NOTIFICATION_EVENT_TYPE.UNIVERSITY_BROADCAST:
                return `New Message from : ${notificationOutDto?.universityName}`;
            default:
                return 'New Message ';
        }
    }

    getLibBottomToolbarBottomConfig(): LibBottomToolbarBottomConfig[] {
        return [
            {
                id: CONSTANT.MARK_ALL_AS_READ,
                type: LibBottomToolbarBottomConfigType.BUTTON,
                iconClass: 'ti ti-checks text-2xl',
                iconPosition: BUTTON_ICON_POSITION.BEFORE,
                buttonText: 'Mark all as read',
                tooltip: 'Mark all as read',
                buttonType: BUTTON_TYPE.BASIC,
                buttonSubType: BUTTON_SUB_TYPE.SECONDARY,
            } as LibBottomToolbarBottomConfig,
            {
                id: CONSTANT.CLEAR_ALL,
                type: LibBottomToolbarBottomConfigType.BUTTON,
                iconClass: 'ti ti-clear-all text-2xl',
                iconPosition: BUTTON_ICON_POSITION.BEFORE,
                buttonText: 'Clear All',
                tooltip: 'Clear All',
                buttonType: BUTTON_TYPE.LARGE,
                buttonSubType: BUTTON_SUB_TYPE.DANGER,
            } as LibBottomToolbarBottomConfig
        ];
    }
}
