import {Component, Input, OnInit} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
    selector: 'lib-flat-card',
    standalone: true,
    templateUrl: './lib-flat-card.component.html',
    imports: [
        NgClass
    ],
    styleUrls: ['./lib-flat-card.component.scss']
})
export class LibFlatCardComponent implements OnInit {
    @Input() imageUrl: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() linkText: string;
    @Input() linkUrl: string;
    @Input() isBorder = true;

    constructor() {
    }

    ngOnInit(): void {
    }

    onClickLink(): void {
        if (this.linkUrl) {
            window.open(this.linkUrl, '_blank');
        }
    }
}
