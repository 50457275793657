<div class="bg-white box-shadow w-full flex p-5 items-center gap-5"
     [ngClass]="isBorder ? 'rounded-xl border-stroke' : 'none'">
    <div class="min-w-14 w-14 h-14 box-shadow rounded-xl overflow-hidden border-stroke">
        <img class="w-full max-w-14 rounded-xl"
             alt="image"
             [src]="imageUrl">
    </div>
    <div class="flex flex-col items-start justify-start">
        <div class="text-sm sm:text-base font-medium text-heading-primary">
            {{ title }}
        </div>
        @if (subtitle) {
            <div class="text-xs sm:text-sm font-normal text-heading-secondary">
                {{ subtitle }}
            </div>
        }
        @if (linkText) {
            <div class="text-xs sm:text-sm text-primary cursor-pointer font-normal"
                 (click)="onClickLink()">
                <span class="underline">
                    {{ linkText }}
                </span>
                <i class="ti ti-arrow-up-right text-primary"></i>
            </div>
        }
    </div>
</div>
