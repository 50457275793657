<div class="notification-snackbar cursor-pointer" (click)="redirect(notificationOutDto)">
    <div class="snack-bar-header-container">
        <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
    </div>
    <div class="flex w-full justify-between items-center snack-bar-content p-4">
        <div class="flex gap-4 items-center snack-bar-heading-container">
            <div class="c-logo">
                <img src="assets/images/talenlio-logo-short-blue.svg">
            </div>
            <div class="snack-bar-heading">
                <div class="text-heading-secondary text-xs sub-title">{{ DateUtil.getRelativeTime(notificationOutDto?.id) }}
                </div>
                <div class="snack-bar-status text-heading-primary text-lg title">
                    {{ NotificationUtil.getInstance().getTitle(notificationOutDto) }}
                </div>
            </div>
        </div>
        <div class="view-cta">
            <div class="big-view-cta">
                <lib-button [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                            (buttonClick)="redirect(notificationOutDto)">
                    View details
                </lib-button>
            </div>
            <div class="small-view-cta">
                <lib-button [libSubType]="BUTTON_SUB_TYPE.SECONDARY"
                            iconClass="ti ti-eye text-2xl"
                            (buttonClick)="redirect(notificationOutDto)">
                </lib-button>
            </div>
        </div>
    </div>
    <div class="divider-line"></div>
    <div class="description-container p-4">
        <div class="description text-sm"
             [innerHTML]="sanitizeNotification(NotificationUtil.getInstance().getDescription(notificationOutDto))">
        </div>
    </div>
</div>
